html,
body,
#root,
.App {
  height: 100%;
  margin: 0;
}

* {
  font-family: "Roboto Mono";
}

.App {
  font-family: sans-serif;
  text-align: center;
}

.chart-pane {
  overflow: hidden;
}

.reflex-size-aware {
  overflow: hidden;
}

.string-error {
  background: rgba(255, 100, 100);
}

.glyph-error {
  background: red;
}
